<template>
  <v-row class="">
    <v-col class="">
      <v-btn class="" color="grey lighten-3" @click="$router.push({ name: 'website.apps' })">
        <v-icon color="" left>
          mdi-arrow-left
        </v-icon>
        Back
      </v-btn>
    </v-col>
    <v-row justify="center">
      <!-- <v-dialog v-model="dialog" persistent max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            Delete private app
          </v-card-title>
          <v-card-text>
            Are you sure you want to delete <span class="font-weight-bold">{{ this.appDel.appName }}</span> ? This will
            revoke all your credentials and may break implementations of your app or storefront. This action can't be
            reversed.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="" outlined @click="cancel">
              Cancel
            </v-btn>
            <v-btn color="error" depressed @click="deleteApp">
              Delete this private app
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </v-row>
    <v-btn color="primary py-5" @click="$router.push({ name: 'website.apps-migrate.create' })">
      <span class="white--text">Create a store migrate</span>
    </v-btn>
    <v-col cols="12" class="d-flex align-center justify-space-between">
      <span class="page-title display-1 font-weight-bold grey--text text--darken-2">{{
        $t('List store migrate to Dcomcy')
      }}</span>
    </v-col>
    <v-row v-if="!isLoading">
      <v-col cols="12" v-if="document && document.length > 0" no-gutters class="elevation-1 mt-2 page__table-container">
        <Resource
          :document="document"
          :updateRouterCallback="updateRouterCallback"
          :filter-fields="null"
          :callbackAction="callbackAction"
          :columns="columns"
        />
        <!-- <v-col cols="12" class="d-flex justify-space-between">
          <limit-page :callback="setLimit" :page.sync="page" />
          <pagination :length="1000000000000" :visible="0" :page.sync="page"></pagination>
        </v-col> -->
      </v-col>
      <v-col cols="12" class="d-flex justify-center" v-else>
        <div class="col-7 px-6">
          <div class="d-flex justify-center">
            <v-img
              max-height="192px"
              max-width="192px"
              :src="`https://admin-cdn.shopbase.com/2f64a69867/img/private-apps-thumb-placeholder.1ffa129a.svg`"
            />
          </div>
          <!-- <div class="text-center py-6">
            Private apps can streamline store processes like accounting, importing products,or even create unique
            customer-facing storefronts.
          </div> -->
          <div class="d-flex justify-center">
            <v-btn color="primary py-5" @click="$router.push({ name: 'website.apps-migrate.create' })">
              <span class="white--text">Migrate to Dcomcy</span>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-sheet color="`grey darken-2" class="pa-3">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-sheet>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import MenuApps from '../components/MenuApps';
import Resource from '@/components/Resources';
import resourceListMixin from '@/mixins/resource-list';
import { migrateApi } from '@/apis/migrate';
export default {
  components: {
    // MenuApps,
    Resource,
  },
  // mixins: [resourceListMixin],
  data() {
    return {
      menu: [],
      dialog: false,
      isLoading: false,
      defaultActiveMenu: '',
      document: [],
      appDel: {},
      columns: {
        // Selectable: {
        //   blankLabel: true,
        //   kind: 'selectable',
        //   width: 1,
        // },
        'URL store': {
          field: 'sourceStoreUrl',
          kind: 'title',
        },
        'Api key': {
          // blankLabel: true,
          field: 'sourceStoreApiKey',
          kind: 'title',
        },
        'Store Type': {
          field: 'sourceStoreType',
          kind: 'title',
        },
        Status: {
          field: 'status',
          kind: 'migrate-status',
        },
        Actions: {
          kind: 'action',
          listAction: [
            // {
            //   name: 'relaunch',
            // },
            // {
            //   name: 'delete',
            // },
          ],
        },
      },
      page: 1,
      limit: 50,
    };
  },
  methods: {
    callbackAction(action, ids) {
      if (action.action === 'delete') {
        this.appDel = this.document.find(app => app._id === ids);
        this.dialog = true;
      }
    },
    async deleteApp() {
      try {
        await migrateApi.deletePrivateApp(this.appDel._id);
        this.$store.commit('setMessages', {
          messages: 'Delete app successfully!',
          type: 'success',
        });
        this.isLoading = true;
        let params = {
          page: 0,
          limit: 50,
        };
        let data = await migrateApi.get(params);
        this.document = data.data.privateApps;
        this.isLoading = false;
      } catch (error) {
        this.$store.commit('setMessages', {
          messages: 'Error',
          type: 'error',
        });
      }
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
    },
    updateRouterCallback(id) {
      this.$router.push({ name: 'website.apps-migrate.edit', params: { id: id } });
    },
    setLimit(limit) {
      this.limit = limit;
    },
  },
  watch: {},
  async created() {
    this.isLoading = true;
    let params = {
      page: 1,
      limit: 100,
    };
    let data = await migrateApi.get(params);
    this.document = data.data.storeDataTransfers;
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
.upsell-page {
  height: 100% !important;
  .upsell-page-container {
    @include flex(center, flex-center);
    overflow-y: auto;
    padding-left: 260px;
    & .upsell-page-content {
      max-width: 1024px;
      min-width: 700px;
      margin-top: 40px;
    }
  }
}
</style>
